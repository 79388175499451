<template>
  <div class="Player container">
    <LeftMenus :menus="menus" :menusHandle="menusHandle" :active="active" />
    <div class="mainContent">
      <div class="head">
        <div class="left">
          <span>{{ $t("home.footballer") }}</span>
        </div>
        <div>{{ $t("home.team") }}</div>
        <div>{{ active == 2 ? $t("home.assist") : $t("home.goal") }}</div>
      </div>
      <div class="content hid-scrollbar">
        <div class="row" v-for="(item, key) in playerData" :key="key">
          <div>
            <span class="index">{{ key + 1 }}</span>
            <div class="imgWrap">
              <img :src="item.ph" alt v-if="item.ph" />
              <img
                v-else
                class="defaultHead"
                :src="require('@/assets/imgs/comm/headDetault.svg')"
                alt
              />
            </div>
            <span>{{ item.pnm }}</span>
          </div>
          <div>{{ item.tnm }}</div>
          <div>{{ active == 2 ? item.ast : item.gs }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenus from "@/components/LeftMenus";
import { getPlayerStatList } from "@/service/Player";
import { mapState } from "vuex";

export default {
  name: "Player",
  components: {
    LeftMenus,
  },
  data() {
    return {
      menus: [
        { groupValue: 1, groupText: this.$t("home.TopCcorer") },
        { groupValue: 2, groupText: this.$t("home.assists") },
      ],
      active: 1,
      playerData: [],
      fetchTimer: null,
    };
  },
  created() {
    this.getPlayerStatList();
  },
  // destroyed() {
  //   clearTimeout(this.fetchTimer);
  //   this.fetchTimer = null;
  // },
  computed: {
    languageType() {
      return this.common.languageType;
    },
    ...mapState(["common"]),
  },
  methods: {
    menusHandle(val) {
      this.active = val;
      this.getPlayerStatList();
    },
    buildData(data) {
      let filedText = this.active == 1 ? "gs" : "ast";
      this.playerData = data.sort(
        (curr, next) => next[filedText] - curr[filedText]
      );
    },
    getPlayerStatList() {
      getPlayerStatList({
        languageType: this.languageType,
        type: this.active,
      }).then((res) => {
        if (res.success) {
          this.buildData(res.data);
          // this.fetchTimer = setTimeout(() => {
          //   this.getPlayerStatList();
          // }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Player {
  display: flex;
  height: 100%;
  .mainContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    border-radius: 20px;
    padding: 0;
    color: $text-color;
      backdrop-filter: blur(10px);
    .head {
      display: flex;
      align-items: center;
      height: 40px;
      border-bottom: 0.5px solid rgba(234, 237, 246, 0.20);
      background: #F0F3F6;
      & > div {
        // &:first-child,
        // &:nth-child(2) {
        flex: 1;
        // }
        // &:last-child {
        //   width: 80px;
        // }
        font-size: 13px;
        text-align: center;
        color: $text-color-light;
      }
      .left {
        text-align: left;
        span {
          padding-left: 42px;
        }
      }
    }
    .content {
      overflow: auto;
      flex: 1;
      
      .row {
        display: flex;
        align-items: center;
        height: 45px;
        border-bottom: 0.5px solid $border-color;
        overflow: hidden;
        flex-wrap: nowrap;
        & > div {
          // &:first-child,
          // &:nth-child(2) {
          flex: 1;
          // }
          // &:last-child {
          //   width: 80px;
          // }
          overflow: hidden;
          font-size: 13px;
          text-align: center;
          &:first-child {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .imgWrap {
              display: inline-block;
              text-align: center;
              line-height: 30px;
              border: 1px solid $border-color;
              overflow: hidden;
              border-radius: 50%;
              margin: 0 8px;
              width: 32px;
              height: 32px;
              vertical-align: middle;
              .defaultHead {
                width: 17.45px;
                height: 17.45px;
                border-radius: 0;
              }
            }
            & > img {
              margin: 0 8px;
            }
            span {
              font-weight: 500;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .index {
              font-family: "DIN";
              font-weight: 700;
              font-size: 12px;
              margin-left: 16px;
              display: inline-block;
              width: 18px;
              height: 18px;
              text-align: center;
              line-height: 18px;
              vertical-align: middle;
            }
          }
          img {
            width: 31px;
            height: 31px;
            border-radius: 50%;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
.dark{
  .Player .mainContent .head{
    background: #000;
  }
  .Player{
    .content{
    background:  #151618;
    }
  }
}
</style>
